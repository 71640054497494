import PropTypes from "prop-types";
import React from "react";
import { navigate } from "gatsby";
import { formatDate } from "../helper/date";
import Button from "./button";
import Label from "./label";

const SearchResultCard = ({
  className,
  title,
  content,
  type,
  organization,
  createdAt,
  author,
  link,
  target,
  readMoreLabelText,
  locale,
}) => {
  const metadata = [formatDate(createdAt, locale), author, organization]
    .filter(entry => !!entry)
    .join(" | ");

  return (
    <article className={`search-result-card ${className}`}>
      {type && <Label type="primary" text={type} className="type" />}
      {metadata && <span className="metadata">{metadata}</span>}
      <h4 className="title">{title}</h4>
      {content && <p>{content}</p>}
      {link && (
        <Button
          type="quaternary"
          text={readMoreLabelText}
          className="more"
          onClick={() => navigate(link)}
        />
      )}
    </article>
  );
};

/* PropTypes */
SearchResultCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  type: PropTypes.string,
  organization: PropTypes.string,
  createdAt: PropTypes.string,
  author: PropTypes.string,
  link: PropTypes.string,
  target: PropTypes.string,
  locale: PropTypes.string,
};

SearchResultCard.defaultProps = {
  className: "",
  target: "",
  locale: "de-DE",
};

export default SearchResultCard;
