import { graphql } from "gatsby";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { renderDynamicComponents } from "../components";
import SearchResultCard from "../components/searchResultCard";
import { getJobLink } from "../helper/link";
import { getValue } from "../helper/translation";
import Layout from "../modules/layout";
import SEO from "../modules/seo";

const Jobs = ({ data: { allJobs }, pageContext }) => {
  const jobs = allJobs.edges.map(edge => edge.node);
  const {
    settings: siteSettings,
    contentComponents,
    node_locale,
  } = pageContext;
  const { microcopy } = siteSettings;

  return (
    <Layout siteSettings={siteSettings}>
      <SEO
        title={`${siteSettings.siteName} - ${getValue(
          "labels.jobs",
          microcopy
        )}`}
        description={siteSettings.siteDescription}
        author={siteSettings.siteAuthor}
      />
      <div className="jobsOverview">
        <Container fluid>
          <h2 className="page-title">{getValue("labels.jobs", microcopy)}</h2>
          <Row>
            <Col md={3} lg={4}>
              {contentComponents &&
                renderDynamicComponents(contentComponents, node_locale)}
            </Col>
            <Col
              md={9}
              lg={8}
              className="d-flex flex-column align-items-md-start"
            >
              {jobs.length === 0 && (
                <SearchResultCard
                  className="job w-100"
                  content={getValue("labels.noJobs", microcopy)}
                />
              )}
              {jobs.map(job => (
                <SearchResultCard
                  className="job w-100"
                  key={job.id}
                  type={job.jobType.name}
                  title={job.title}
                  content={job.reader}
                  readMoreLabelText={getValue("actions.moreInfo", microcopy)}
                  link={getJobLink(job.title, job.node_locale)}
                />
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($node_locale: String!) {
    allJobs: allContentfulJob(filter: { node_locale: { eq: $node_locale } }) {
      edges {
        node {
          contentful_id
          id
          title
          reader
          node_locale
          description {
            json
          }
          jobType {
            name
          }
          link
        }
      }
    }
  }
`;

export default Jobs;
